import AOS from "aos";
// import "aos/dist/aos.css";
import "./contact";

// AOS.
AOS.init();

// Mobile menu
const menuToggle = document.getElementById("menu-toggle");
const menu = document.getElementById("menu");
const fadeScreen = document.getElementById("fade-screen");

menuToggle.addEventListener("click", (event) => {
  event.preventDefault();
  fadeScreen.classList.toggle("hidden");
  menu.classList.toggle("-translate-x-256");
});

fadeScreen.addEventListener("click", () => {
  fadeScreen.classList.toggle("hidden");
  menu.classList.toggle("-translate-x-256");
});
