const contactForm = document.getElementById("contact-form");

if (contactForm) {
  contactForm.addEventListener("submit", event => {
    event.preventDefault();

    const form = new FormData(contactForm);

    // Connect to mail endpoint.
    fetch(contactForm.action, {
      method: "post",
      body: form
    })
      .then(response => response.json())
      .then(body => {
        if (body.status === 200) {
          window.location.href = "/thank-you";
        }
      });
  });
}
